import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const ProjectCard = ({
  title,
  short_desc,
  footer_desc,
  banner,
  slug,
  onClick = null,
  ribbon_text = ""
}) => {
  return (
    <Link
      className="button-as-link project-card"
      to={`/${slug}/`}
      onClick={onClick}
    >
      <div className="relative">
        {ribbon_text && <div className="ribbon3">{ribbon_text}</div>}
        {banner?.childImageSharp && false ? <Img
          className="project-card-img"
          fluid={banner?.childImageSharp?.fluid}
          alt={title}
        /> : <div className="project-card-img"><img src={banner?.childImageSharp?.fluid?.src} alt={title} /></div>}
      </div>
      <div className="project-card-body">
        <h5 className="project-card-title">{title}</h5>
        {short_desc && <p className="project-card-desc">{short_desc}</p>}
      </div>
      <div className="project-card-footer">{footer_desc}</div>
    </Link>
  );
};

export default ProjectCard;
