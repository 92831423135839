import React from "react";
import OngoingList from "../projects/OngoingList";
import Layout from "../common/Layout";
import ProjectCard from "../projects/ProjectCard";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BlogItem from "./BlogItem";
import { Helmet } from "react-helmet";

const BlogDetail = ({ data }) => {
  const blog = data.blogJson;

  const {
    slug,
    title,
    content,
    image,
    created_on,
    author,
    short_desc,
    curated_by = [],
    hide_date,
    hide_image,
  } = blog;
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={short_desc || ""} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.bashyamgroup.com/${slug}`}
        />
        {image && image.childImageSharp && image.childImageSharp.fluid && (
          <meta
            property="og:image"
            content={
              "https://www.bashyamgroup.com" + image.childImageSharp.fluid.src
            }
          />
        )}
      </Helmet>
      <div className="container pt-4">
        <div className="row mb-3">
          <div className="col-lg-8">
            <h2 className="mb-2 font-weight-bold">{title}</h2>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                {curated_by && curated_by.length > 0 ? (
                  curated_by.map((x, id) => (
                    <div
                      className="d-flex justify-content-center blog-author mr-1"
                      key={`${slug} ${id}`}
                    >
                      <div className="p-1">{x.title}</div>
                      <div className="p-1">:</div>
                      <div className="p-1">{x.desc}</div>
                    </div>
                  ))
                ) : (
                  <div className="blog-authour-name">
                    {hide_date ? (
                      <>By {author}</>
                    ): (
                      <>By {author} on {created_on}</>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3">
              {!hide_image && image &&
                image.childImageSharp &&
                image.childImageSharp.fluid && (
                  <Img
                    className=""
                    fluid={image.childImageSharp.fluid}
                    alt={title}
                  />
                )}
            </div>
            <div
              className="blog-detail-desc text-justify mb-5"
              dangerouslySetInnerHTML={{ __html: content || "" }}
            />
            <h4 className="mb-3">Up Next</h4>
            <div className="row">
              {data.nextBlogs.edges.map(({ node }) => (
                <BlogItem conClass="col-lg-6 mb-5" {...node} key={node.id} />
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <h5 className="mb-3">Residential Ongoing Projects</h5>
            <OngoingList
              RenderItem={props => (
                <div className="mb-3">
                  <ProjectCard {...props} />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogDetailQuery($slug: String!) {
    blogJson(slug: { eq: $slug }) {
      author
      content
      id
      short_desc
      slug
      title
      created_on(locale: "en-IN", formatString: "LL")
      curated_by {
        desc
        title
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hide_date
      hide_image
    }
    nextBlogs: allBlogJson(
      filter: { slug: { ne: $slug } }
      limit: 2
      sort: { order: DESC, fields: created_on }
    ) {
      edges {
        node {
          author
          id
          short_desc
          slug
          title
          created_on(locale: "en-IN", formatString: "LL")
          curated_by {
            desc
            title
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hide_date
          hide_image
        }
      }
    }
  }
`;

export default BlogDetail;
